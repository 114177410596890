var $body = $("body");
var $favContainer = $("header").find("#favourites-container");
var $close = $favContainer.find(".close-slide");

var $cartToggle = $(".cart");
var $cartContainer = $("header").find("#cart-container");

var $addToCart = $(".add-to-cart");
var $addToCartQuantity = $(".add-to-cart-quantity");

$close.on("click", function () {
    "use strict";
    if ($favContainer.hasClass("show")) {
        $favContainer.removeClass("show");
    }
    if ($body.hasClass("overlay")) {
        $body.removeClass("overlay");
    }
});

var $closeCart = $cartContainer.find(".close-slide");
$closeCart.on("click", function () {
    "use strict";
    if ($cartContainer.hasClass("show")) {
        $cartContainer.removeClass("show");
    }
    if ($body.hasClass("overlay")) {
        $body.removeClass("overlay");
    }
});

var $accordionToggle = $("#accordionDelivery .card .card-header").find(".toggle");
var $radioButtonCheck = $accordionToggle.find(".radio-button > .radio-inner");
$accordionToggle.on("click", function () {
    "use strict";
    var $radioButton = $(this).find(".radio-inner");
    $radioButtonCheck.removeClass("checked");
    if ($radioButtonCheck.hasClass("checked")) {
        $radioButtonCheck.removeClass("checked");
    } else {
        $radioButton.addClass("checked");
    }
});

var $accordionPaymentToggle = $("#accordionPayment .card .card-header").find(".toggle");
var $radioButtonCheckPayment = $accordionPaymentToggle.find(".radio-button > .radio-inner");
$accordionPaymentToggle.on("click", function () {
    "use strict";
    var $radioButton = $(this).find(".radio-inner");
    $radioButtonCheckPayment.removeClass("checked");
    if ($radioButtonCheckPayment.hasClass("checked")) {
        $radioButtonCheckPayment.removeClass("checked");
    } else {
        $radioButton.addClass("checked");
    }
});

var $paymentToggle = $("#checkout").find(".payment-toggle");
var $paymentContainer = $("#checkout").find("#order-details");
$paymentToggle.on("click", function () {
    $(this).toggleClass("toggle");
    $paymentContainer.toggleClass("expanded");
    $body.toggleClass("overflow-hidden");
});

(function () {
    'use strict';
    window.addEventListener('load', function () {
        var forms = document.getElementsByClassName('needs-validation');
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.addEventListener('submit', function (event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                    $(form).addClass('has-errors');
                } else {
                    $(form).removeClass('has-errors');
                    $(form).addClass('submitting');
                }
                form.classList.add('was-validated');
            }, false);
        });
    }, false);
})();

var Checkout = function (container, type) {
    this.initialize();
};
Checkout.prototype = {

    initialize: function () {
        var self = this;
        if ($("#account-checkbox").is(':checked')) {
            $(".form-password").show().find('input').prop('required', true);
        }

        $("#account-checkbox").on('click', function () {
            if ($(this).is(':checked')) {
                $(".form-password").show().find('input').prop('required', true);
            } else {
                $(".form-password").hide().find('input').prop('required', false);
            }
        });

        $(".delivery-address").find('input').prop('required', false);
        $("#delivery-checkbox").on('click', function () {
            if ($(this).is(':checked')) {
                $(".delivery-address").show().find('input').prop('required', true);
            } else {
                $(".delivery-address").hide().find('input').prop('required', false);
            }
        });

        $(".address-card").on('click', function () {
            $(".address-card.active.selected").removeClass(['active', 'selected']);

            $(this).addClass(['active', 'selected']);

            var address = $(this).data('address');

            $(".delivery-address input[name=delivery_address_id]").val(address.id);

            Object.keys(address).forEach(function (key) {
                $(".delivery-address input[name=delivery_" + key + "]").val(address[key]);
            });
        });

        $("#delivery input, #payment input").on('change', function () {
            self.saveDeliveryChoice(
                $("input[name=payment_type_id]:checked").val(),
                $("input[name=delivery_type_id]:checked").val()
            );
        });

        $.get("/cart/checkout-summary", function (html) {
            self.applySummary(html);
        });

        self.changeDeliveryAddres($("#checkoutDeliveryAddress").val());
        $("#checkoutDeliveryAddress").on('change', function () {
            self.changeDeliveryAddres($(this).val());
        });
    },
    saveDeliveryChoice: function (payment, delivery) {
        var self = this;
        $.post('/cart/checkout-delivery', {
            payment_type_id: payment != null ? payment : null,
            delivery_type_id: delivery != null ? delivery : null
        }, function (html) {
            self.applySummary(html);
        });
    },
    applySummary: function (html) {
        var self = this;
        $(".checkout-summary-placeholder").html(html);
    },
    changeDeliveryAddres: function (address) {
        if (!address) return;

        var addressData = JSON.parse(address);
        $("#billing_address_id").val(addressData.id);
        $("#billing_firstname").val(addressData.firstname);
        $("#billing_lastname").val(addressData.lastname);
        $("#billing_phone").val(addressData.phone);
        $("#billing_address").val(addressData.address);
        $("#billing_zip").val(addressData.zip);
        $("#billing_city").val(addressData.city);
        $("#billing_country_id").val(addressData.country_id);
    }
};

if ($("#checkout").length > 0) {
    new Checkout();
}

var companyCheckbox = $(".personal").find("#company-checkbox");
var companyCont = $(".personal").find("#company");
companyCheckbox.on("click", function () {
    $(companyCont).toggleClass("show");
    var companyAddFields = $(companyCont).find("input[type=text]");
    $(companyAddFields).each(function (key, field) {
        $(field).attr("required", true);
    })
});

setTimeout(function () {
    if (companyCheckbox.is(":checked")) {
        $(companyCont).addClass("show");
    }
}, 500);
